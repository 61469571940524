<template>
  <v-container
    fluid
    class="mt-1"
  >
    <validation-observer v-slot="{ valid }">
      <base-material-card
        color="primary"
        icon="mdi-file-table-box-multiple-outline"
        inline
        class="px-5 py-4 ml-0"
      >
        <template v-slot:after-heading>
          <div class="display-1 font-weight-light">
            Adicionar Grupo de Relatório
          </div>
        </template>
        <v-row class="mt-3">
          <v-col cols="9">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:30"
            >
              <v-text-field
                id="nome-grupo-relatorios"
                label="Nome"
                outlined
                v-model="titulo"
                :error-messages="errors"
                class="ml-4"
              />
            </validation-provider>
          </v-col>
          <v-col cols="3">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              id="exclusivo-grupo-relatorios"
            >
              <v-select
                v-model="exclusivo"
                :items="['Sim', 'Não']"
                label="Exclusivo"
                :error-messages="errors"
                outlined
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mt-n6">
          <v-col
            cols="12"
            md="6"
            class="pt-0"
          />
          <v-col
            cols="12"
            md="6"
            class="pr-0"
          >
            <v-btn
              id="btn-save-grupos-relatorios"
              class="mr-3"
              color="primary"
              min-width="100"
              style="float: right"
              :loading="loading"
              :disabled="!valid"
              @click="salvar()"
            >
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </base-material-card>
    </validation-observer>
  </v-container>
</template>

<script>
import GruposRelatoriosService from '@/services/GruposRelatoriosService';
import tourMixins from '@/mixins/tourMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [tourMixins],

  data: () => ({
    titulo: '',
    loading: false,
    exclusivo: 'Não',
    arrTour: [
      {
        element: '#nome-grupo-relatorios',
        intro:
          'Aqui você deverá escolher um nome para seu grupo de relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#exclusivo-grupo-relatorios',
        intro:
          'Aqui você deverá informar se este grupos de relatórios modelos é exlusivo ou seja se ele deve aparecer apenas na empresa conectada ou em todas.',
        scrollTo: 'tooltip',
        position: 'right'
      },
      {
        element: '#btn-save-grupos-relatorios',
        intro:
          'Após o preenchimento / seleção dos critérios anteriores clique aqui para salvar seu grupo de relatórios modelos.',
        scrollTo: 'tooltip',
        position: 'left'
      }
    ]
  }),

  created() {
    if (this.$route.params.id) this.getGrupo(this.$route.params.id);

    this.iniciarTourPagina();
  },

  methods: {
    getGrupo(id) {
      GruposRelatoriosService.getGrupoRelatorio(id)
        .then((response) => {
          const rData = response.data.data;
          this.exclusivo = rData.exclusivo ? 'Sim' : 'Não';
          this.titulo = rData.titulo;
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar grupo de relatório.', '', {
            position: 'topRight'
          })
        );
    },

    salvar() {
      this.loading = true;
      let data = {
        id: this.$route.params.id ? this.$route.params.id : null,
        titulo: this.titulo,
        exclusivo: this.exclusivo == 'Sim' ? true : false
      };

      GruposRelatoriosService.save(data)
        .then(() => {
          this.$toast.success('Grupo de Relatório salvo com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['GRUPOS_RELATORIOS_HISTORICO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar grupo de relatório.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    },

    iniciarTourPagina() {
      if (this.tourFuncCalled) return;

      this.iniciarTour(this.arrTour);
    }
  }
};
</script>
